<template>
  <q-page class="row" padding>
    <q-card
      class="col-12 q-mb-md"
      v-bind:key="theme.title"
      v-for="theme in filteredThemes"
    >
      <q-img
        :src="'statics/themes/'+theme.slug+'.jpg'"
        @click="start(theme)"
      >
        <div class="absolute-bottom">
          <div class="text-h6">
            {{theme.imageCaption}}
          </div>
        </div>
      </q-img>

      <q-card-actions
        align="around"
      >
        <q-btn
          :id="'start_theme_from_card_' + theme.slug"
          @click="start(theme)"
          class="full-width"
          icon="mic"
          color="primary"
          no-caps
        >{{theme.intro}}
        </q-btn>


        <q-btn
          :id="'create_your_own'"
          :to="'/grill/create/' + theme.slug"
          class="full-width q-mt-sm"
          color="secondary"
          icon="people"
          no-caps

        >Create your own {{theme.title}} and send to your "friends"
        </q-btn>
      </q-card-actions>


    </q-card>

  </q-page>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: 'GrillList',
        methods: {
            start (theme) {
              this.$router.push('/at/' + theme.slug)
            },
        },
        computed: {
            ...mapGetters(['publicDocuments', 'themes']),
            filteredThemes () {
                return Array.prototype.slice.call(this.themes).sort((a, b) => a.sort - b.sort)
            },
        },
        data () {
            return {}
        },
        mounted () {
            window.jim = window.jim || {}
            window.jim.GrillList = this
            window.jim_DEBUG_FULL = true


            if (this.$route.query.script) {
                this.$router.push('/grill/')
            } else {
            }

        },
        created () {
        },
    }
</script>

<style>
</style>
